/* global $, openTab */

/**
 * Utility to wrap the different behaviors between W3C-compliant browsers
 * and IE when adding event handlers.
 *
 * @param {Object}      element Object on which to attach the event listener.
 * @param {string}      type A string representing the event type to listen for
 *                      (e.g. load, click, etc.).
 * @param {function()}  callback The function that receives the notification.
 */
function addListener(element, type, callback) {
    if (element.addEventListener) {
        element.addEventListener(type, callback);
    } else if (element.attachEvent) {
        element.attachEvent('on' + type, callback);
    }
}


function createCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
}

(function() {
    var buttonAccept = document.getElementsByClassName('cookiesettings__card--accept')[0],
        buttonDecline = document.getElementsByClassName('cookiesettings__card--decline')[0];

    if ($(".cookiesettings__card--accept")[0]) {
        buttonAccept.onclick = function () {
            createCookie('cookieSet', true, 10000);
            location.reload();
        };

        buttonDecline.onclick = function () {
            createCookie('cookieSet', 'declined', 10000);
            location.reload();
        };
    }
})();

function isIE() {
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    var notificationIE = document.getElementsByClassName('notificationIE')[0];
    var exit = document.getElementsByClassName('notificationIE-exit')[0];
    if (isIE === true) {
        notificationIE.classList.add('block');
        exit.onclick = function() {exitIEFunction()};
        function exitIEFunction() {
            notificationIE.parentNode.removeChild(notificationIE);
        }
    } else {
        notificationIE.parentNode.removeChild(notificationIE);
    }
}
isIE();

$('.js-scroll-to').click(function(e){
    e.preventDefault();
    $([document.documentElement, document.body]).animate({
        scrollTop: $(".element").offset().top
    }, 500);
});

$('.js-collapse').click(function (e) {
    e.preventDefault();
    $(this).siblings('.fold-area').toggleClass('active');
    if ($(this).siblings('.fold-area').hasClass('active')) {
        $(this).html('Lees minder');
    } else {
        $(this).html('Lees meer');
    }
});

$('.faq__item').click(function(e){
    e.preventDefault();
    $(this).toggleClass('active');
});

$( ".menu__list" ).has( "ul" ).addClass('has-children');

$('.has-children').click(function(){
    $(this).toggleClass('open-parent');
    $(this).find('.menu').toggleClass('open');
});


$('.zoomButton').click(function(){
    $(this).parent('.imageZoom').toggleClass('active');
    var html = $('html');
    var body = $('body');

    html.toggleClass('overflow-hidden');
    body.toggleClass('overflow-hidden');
});
