import styles from '../css/app.pcss';
import images from './images.js'
import font from '../fonts/futura.ttf';
import Swiper from 'swiper';
require('es6-promise').polyfill();

require('./site');
// App main
const main = async () => {
    // Import our CSS
    //const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
    // Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue');

    // Create our vue instance
    const vm = new Vue.default({
        el: "#site",
        delimiters: ['${', '}'],
        components: {
            'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
            'parallax': () => import(/* webpackChunkName: "parallax" */ '../vue/parallax.vue'),
        },
        data: {
            'mobileClass': false,
            'modal' : false,
            'modal2' : false,
            'modal3' : false,
        },
        methods: {
            openMobileNav: function() {
                this.mobileClass = !this.mobileClass;
                if (this.mobileClass) {
                    document.getElementsByTagName("html")[0].setAttribute("class", "overflow-hidden");
                    document.getElementsByTagName("body")[0].setAttribute("class", "overflow-hidden");
                } else {
                    document.getElementsByTagName("html")[0].setAttribute("class", "");
                    document.getElementsByTagName("body")[0].setAttribute("class", "");
                }
            },
            toggleModal: function() {
                this.modal = !this.modal
            },
            toggleModal2: function() {
                this.modal2 = !this.modal2
            }
        },
        mounted() {
            require('./javascript');
            require('./jquery-scripts');

            var sponsorSlider = new Swiper('.js-sponsor-slider', {
                    slidesPerView: 2,
                    loop: true,
                    spaceBetween: 51,
                    autoplay: {
                        delay: 2500
                    },
                    breakpoints: {
                        600: {
                            spaceBetween: 5,
                        }
                    }
                });

        }
    });
};
// Execute async function
main().then( (value) => {

});
